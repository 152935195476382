import React, { useEffect } from "react";
import { AboutHome, Campaign, Container, NewsBlog } from "../components";
import Seo from "../components/seo";

const IndexPage = () => {
  useEffect(() => {});
  return (
    <Container>
      <Seo
        title="HOME"
        description="恵比寿美容皮膚科では、患者様の皮膚の疾患だけではなく皮膚に対するアンチエイジング療法や肌トラブルそのものに対する機能改善を目指して診療・施術を行います。シミ、シワ、毛穴の開きなど皮膚の老化を改善する治療のほか、スキンケアの指導を行います。"
      />
      {/* <TopBanner /> */}
      {/* <CovidPrevention /> */}
      <Campaign />
      <AboutHome />
      <NewsBlog />
    </Container>
  );
};

export default IndexPage;
